import ReactGA from 'react-ga';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-63050498-2');
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.pageview(location.pathname + location.search);
    }
};
